.lesson-content-container {
  margin-top: 20px;
  margin-bottom: 80px;
}

.reference-links-title {
  color: #67b554;
  font-size: 25px;
  font-family: MontserratBold;
  margin-left: 50px;
}

.topic-container {
  margin-bottom: 20px;
}

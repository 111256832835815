@font-face {
  font-family: MontserratRegular;
  src: url("./asset/fonts/Montserrat-Regular.ttf");
}

@font-face {
  font-family: MontserratBold;
  src: url("./asset/fonts/Montserrat-Bold.ttf");
}

@font-face {
  font-family: LemonMilkRegular;
  src: url("./asset/fonts/LEMONMILK-Regular.otf");
}

@font-face {
  font-family: LemonMilkBold;
  src: url("./asset/fonts/LEMONMILK-Bold.otf");
}

.chapter-button {
    display: inline;
    padding: 5px 20px;
    border: solid 2px #67b554;
    margin: 0 10px;
    border-radius: 10px;
    cursor: pointer;
    font-family: MontserratBold;
    color: #67b554;
    cursor: pointer;
}

.download-button-1 {
    background-color: #67b554;
    width: 200px;
    text-align: center;
    color: white;
    font-family: LemonMilkRegular;
    padding: 5px;
    border-radius: 20px;
    cursor: pointer;
    display: inline-block;
    margin-right: 10px;
}

.download-button-2 {
    background-color: #67b554;
    width: 150px;
    text-align: center;
    color: white;
    font-family: LemonMilkRegular;
    font-size: 15px;
    padding: 3px;
    border-radius: 20px;
    display: inline-block;
    margin-right: 10px;
    cursor: pointer;
}

.reference-link {
    display: block;
    margin-left: 150px;
    font-family: Montserratregular;
    font-size: 15px;
    cursor: pointer;
}

.active {
    background-color: #67b554;
    color: white !important;
}

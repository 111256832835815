.logout-button-wrapper img {
  width: 100%;
  cursor: pointer;
}

.logout-button-wrapper:hover {
  /* background-color: red; */
  /* padding: 10px;
  border-radius: 100%; */
}

.logout-button-wrapper:hover img {
  filter: invert(100%);
}

.grade-level {
  font-size: 50px;
  font-family: LemonMilkBold;
  color: #67b554;
  display: block;
}

.chapter-number {
  font-size: 40px;
  font-family: LemonMilkBold;
  color: #393939;
  display: block;
}

.chapter-title {
  font-size: 30px;
  font-family: LemonMilkBold;
  color: #393939;
  display: block;
}

.lesson-title {
  font-size: 30px;
  font-family: MontserratBold;
  color: #393939;
  display: block;
}

.topic-title {
  font-size: 23px;
  font-family: MontserratBold;
  color: #393939;
  display: block;
  margin-left: 100px;
}

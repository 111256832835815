.log-in-container {
    padding: 20px 10px;
}

.log-in-school-logo {
    width: 100%;
}

.log-in-code-text {
    font-family: MontserratBold;
    letter-spacing: 1px;
}

.log-in-data-container {
    position: relative;
}

.log-in-data-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 325px;
}

.log-in-passcode {
    border-radius: 20px;
    padding: 5px 0 5px 15px;
    border: solid lightgreen 4px;
    width: 200px;
    display: inline-block;
}

.submit-button {
    background-color: lightgreen;
    padding: 5px 20px;
    margin-left: 10px;
    border-radius: 5px;
    cursor: pointer;
    font-family: LEMONMILKREGULAR;
}

.module-content-container {
  max-width: 1400px;
  margin: auto;
  margin-top: 10px;
  padding: 10px;
  padding: 20px;
}

.module-chapter-navigation-container {
  margin: auto;
  text-align: center;
}

.module-content-page-break {
  border: 3px solid #67b554;
  width: 400px;
  margin-left: 0;
}

.chapter-details-container {
  margin-top: 20px;
}

.software-links-container {
  margin-top: 30px;
  margin-bottom: 60px;
}

.module-loading-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.MuiCircularProgress-colorPrimary {
  color: #67b554 !important;
}
.module-header-conatiner {
  background-color: #deffd6;
  color: #439d63;
  padding: 20px;
  position: relative;
  height: 150px;
  cursor: pointer;
}

.module-header-wrapper {
  position: absolute;
  background-color: red;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 450px;
}

.module-header-logo {
  position: absolute;
  width: 80px;
  top: 50%;
  transform: translateY(-50%);
}

.module-title-wrapper {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  display: inline-block;
}

.module-header-title {
  font-family: LemonMilkBold;
  font-size: 35px;
}

.module-header-sub-title {
  font-family: MontserratBold;
  font-size: 13px;
  letter-spacing: 3px;
}

.logout-button-container {
  position: absolute;
  top: 5px;
  right: 20px;
  width: 30px;
}

.school-card-container {
  text-align: center;
  padding: 50px 30px;
}

.school-card-wrapper {
  display: inline-block;
  margin: 10px;
  width: 200px;
  padding: 10px;
  cursor: pointer;
  border-radius: 10px;
  transition: 1s ease;
}

.school-card-wrapper:hover {
  background-color: lightgreen;
}

.school-card-school-logo {
  width: 100%;
}

.school-card-school-code {
  font-family: LEMONMILKBOLD;
  font-size: 25px;
  color: black;
}

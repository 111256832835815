.digital-module-text {
  font-family: MontserratBold;
  color: #67b554;
  font-size: 30px;
}

.software-links-text {
  font-family: MontserratBold;
  color: #67b554;
  font-size: 30px;
  display: block;
}

.software-links-title-text {
  display: inline;
  font-family: MontserratBold;
  font-size: 20px;
}
